* {
  box-sizing: border-box;
}

body {
  font-family: "Jet Brains Mono";
}

.done {
  color: var(--colors-interface-success);
}

.pending {
  color: #fff;
}