.nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.link {
    composes: text text_type_main-medium mb-10 mr-10 from global;
    color: var(--text-inactive-color);
    text-decoration: none;
}

.active {
    color: var(--text-primary-color);
}