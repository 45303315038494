.order_details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.number {
    composes: mt-3 text text_type_digits-large from global;
    text-shadow: 0px 0px 16px rgba(51, 51, 255, 0.25), 0px 0px 8px rgba(51, 51, 255, 0.25), 0px 4px 32px rgba(51, 51, 255, 0.5);
}

.title {
    composes: mt-9 mb-15 text text_type_main-medium from global;
}

.image {
    width: 158px;
    height: 120px;
}

.status {
    composes: mt-15 text text_type_main-default from global;
}

.alert {
    composes: mt-3 mb-15 text text_type_main-default text_color_inactive from global;
}