.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 88px);
    text-align: center;
  }
  
  .link {
    color: var(--colors-interface-accent);
  }

  .image {
    background-image: url(../../../images/robot.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 200px;
    height: 200px;
  }