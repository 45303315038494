.constructor_container {
    composes: pt-25 pr-5 from global;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
}

.constructor_space {
    composes: p-5 from global;
    outline: 1px dashed var(--colors-interface-accent);
    border-radius: 20px;
    width: 100%;
    min-height: calc(100vh - 290px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center
}

.active {
    box-shadow: 0 0 5000px 1px var(--colors-interface-accent);
}

.ingredients {
    composes: mt-3 mb-3 from global;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    overflow-y: scroll;
    max-height: calc(100vh - 470px);
}

.empty_constructor {
    color: var(--colors-interface-accent);
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
    margin-top: calc(50vh - 200px);
}

.constructor_footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.total_wrapper {
    composes: mr-10 from global;
}

.total {
    composes: text text_type_digits-medium mr-2 from global;
}

.ingredients::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(47, 47, 55, 1);
}

.ingredients::-webkit-scrollbar-thumb {
    background-color: rgba(133, 133, 173, 1);
}

.ingredients::-webkit-scrollbar-thumb:hover {
    background-color: rgb(99, 99, 128);
}
