.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 64px);
}

.wrapper {
    width: 720px;
}

.title {
    text-align: center;
}