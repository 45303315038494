.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
}

.card {
    
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
}