.ingredients_item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
}

.active {
    opacity: 0.5;
    
}