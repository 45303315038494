.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: calc(100vh - 88px);
}

.main {
    width: 100%;
}

.input {
    composes: mb-6 from global;
}

.error {
    composes: text text_type_main-medium mb-6 from global;
    color: var(--colors-interface-error);
}

.success {
    composes: text text_type_main-medium mb-6 from global;
    color: var(--colors-interface-success);
}