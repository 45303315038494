.wrapper {
    composes: pt-10 from global;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; 
}

.title {
    composes: text text_type_main-large pb-5 from global;
}

.feedWrapper {
    composes: pr-10 from global;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh;
}

.feed, .feedInfo {
    width: 50%;
}

.feedWrapper::-webkit-scrollbar {
    width: 8px;
    background-color: var(--background-element);
}

.feedWrapper::-webkit-scrollbar-thumb {
    background-color: var(--text-inactive-color);
}

.feedInfoStatusWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.orderTotal {
   composes: text text_type_digits-large from global; 
   text-shadow: 0 0 50px var(--colors-interface-accent);
}

.feedInfoStatus {
    width: 50%;
}

.feedInfoStatusItemTitle {
    composes: pb-7 from global;
    font-size: 1.5rem;
}

.feedInfoStatus ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.feedInfoStatusItem {
    composes: text_type_digits-default from global;
    color: var(--colors-interface-success);
    list-style: none;
    margin: 0;
    padding: 0;
    width: 50%;
}

.feedInfoStatusList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.orderTotalTitle {
    composes: pt-7 from global;
    font-size: 1.5rem;
}