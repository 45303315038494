.price_wrapper {
    composes: pt-1 from global;
    display: flex;
    align-items: center;
    justify-content: center;
}

.price {
    composes: text text_type_digits-default mr-2 from global;
}

.name {
   composes: pt-2 text text_type_main-default from global; 
}

.ingredient {
    text-decoration: none;
    color: var(--text-primary-color);
}