.container {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
}

.main {
    composes: container;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

