.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 5;
}

.modal_content {
    composes: pt-10 pb-10 pl-0 pr-3 from global;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 720px;
    min-height: 530px;
    background-color: var(--background);
    border: 1px solid var(--colors-interface-accent);
    box-sizing: border-box;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: var(--common-border-radius-s);
    box-shadow: 0 0 5000px 1px var(--colors-interface-accent);
}



.modal_header {
    display: flex;
    height: 64px;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
}

.title {
    composes: ml-15 from global;
    font-size: 36px;
    font-weight: 700;
}

.close {
    composes: mr-10 from global;
    cursor: pointer;
    color: var(--text-color);
    transition: color 0.3s ease-in-out;
}

.modal_body {
    composes: pr-15 pl-15 from global;
}