.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 88px);
  }
  
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  
  .title {
    composes: text text_type_main-medium mb-6 from global;
  }
  
  .input {
    composes: mb-6 from global;
  }
  
  .input__container {
    width: 100%;
  }
  
  .button {
    composes: mb-20 from global;
  }
  
  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .label {
    composes: mr-4 from global;
    color: var(--text-inactive-color);
  }
  
  .link {
    color: var(--colors-interface-accent);
    text-decoration: none;
  }

  .error {
    composes: text text_type_main-medium mb-6 from global;
    color: var(--colors-interface-error);
  }

  .success {
    composes: text text_type_main-medium mb-6 from global;
    color: var(--colors-interface-success);
  }